import { joinURL } from 'ufo'
import type { ProviderGetImage } from '@nuxt/image'
import { createOperationsGenerator } from '#image'

const operationsGenerator = createOperationsGenerator({
    keyMap: {
        fit: 'func',
        quality: 'q',
    },
    valueMap: {
        fit: {
            cover: 'crop',
            contain: 'fit',
            fill: 'cover',
            inside: 'bound',
            outside: 'boundmin'
        }
    },
    joinWith: '&',
    formatter: (key, value) => `${key}=${value}`
})


export const getImage: ProviderGetImage = (src, {
    modifiers = {},
    baseURL = '',
    token = '',
    apiVersion = '',
    cdnURL = ''
}) => {

    if (!baseURL) {
        // also support runtime config
        baseURL = useRuntimeConfig().public.siteUrl
    }

    if (modifiers.width && modifiers.height) {
        modifiers.resize = `${modifiers.width}x${modifiers.height}`
        delete modifiers.width
        delete modifiers.height
    }

    if (modifiers.width) {
        let size = 'sm';

        const width = parseInt(modifiers.width, 10); // Convert to integer

        if (width > 1750) {
            size = "/2xl/";
        } else if (width > 870) {
            size = "/xl/";
        } else if (width > 653) {
            size = "/lg/";
        } else if (width > 435) {
            size = "/md/";
        } else if (width > 290) {
            size = "/sm/";
        } else if (width > 170) {
            size = "/xs/";
        } else {
            size = "/xxs/";
        }
        src = src.replace('/max/', size)
    }
    const operations = operationsGenerator(modifiers)


    return {
        url: joinURL(baseURL, src + (operations ? '?' + operations : '')),
    }
}